/*=============== 6.6 Login Page ==================*/
.login-page-wrap {
  background-image: url('../Images/loginbg.png');
  background-repeat: no-repeat;
  background-position: top;
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%; */
}
.enquiryrow{
  background-image: url('../Images/loginbg.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
@media only screen and (max-width: 1024px) {
  .login-page-wrap {
    position: relative;
    height: auto;
  }
}
.login-page-content {
  height: 100vh;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 50px; */
}
@media only screen and (max-width: 575px) {
  .login-page-content {
    padding: 50px 20px;
  }
}
.login-page-content .login-box {
  min-width: 25%;
  padding: 3rem 4rem;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 49px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 49px 0px rgba(0, 0, 0, 0.5);
  max-width: 50%;
}
@media only screen and (max-width: 575px) {
  .login-page-content .login-box {
    min-width: 300px;
    padding: 6rem 4rem;
  }
}
@media only screen and (max-width: 479px) {
  .login-page-content .login-box {
    padding: 6rem 2rem;
  }
}
.login-page-content .login-box .item-logo {
  text-align: center;
  margin-bottom: 40px;
}
.login-page-content .login-box .form-box .form-group {
  position: relative;
  margin-bottom: 18px;
}
.login-page-content .login-box .form-box .form-group > label {
  font-size: 15px;
  font-weight: 600;
  color: #444444;
  margin-bottom: 10px;
  display: block;
  text-align: left;
}
.login-page-content .login-box .form-box .form-group .form-control {
  background-color: transparent;
  border: 1px solid #c2c2c2;
  padding: 5px 35px 5px 20px;
}
.login-page-content .login-box .form-box .form-group i {
  position: absolute;
  top: 60%;
  right: 18px;
  font-size: 15px;
  color: #9f9f9f;
}
.login-page-content .login-box .form-box .form-group input.form-control::-webkit-input-placeholder,
.login-page-content .login-box .form-box .form-group textarea.form-control::-webkit-input-placeholder {
  color: #adadad;
  font-size: 15px;
}
.login-page-content .login-box .form-box .form-group input.form-control::-moz-placeholder,
.login-page-content .login-box .form-box .form-group textarea.form-control::-moz-placeholder {
  color: #adadad;
  font-size: 15px;
}
.login-page-content .login-box .form-box .form-group input.form-control:-ms-input-placeholder,
.login-page-content .login-box .form-box .form-group textarea.form-control:-ms-input-placeholder {
  color: #adadad;
  font-size: 15px;
}
.login-page-content .login-box .form-box .form-group .form-check .form-check-label {
  cursor: pointer;
  font-size: 14px;
  padding-left: 30px;
  font-weight: 500;
  color: #444444;
}
.login-page-content .login-box .form-box .form-group .form-check .form-check-label:before {
  top: 1px;
}
.login-page-content .login-box .form-box .form-group .form-check .form-check-label:after {
  top: 2px;
}
.login-page-content .login-box .form-box .form-group .forgot-btn {
  font-size: 14px;
  color: #1e4242;
  font-weight: 600;
}
.login-btn {
  cursor: pointer !important;
  width: 100% !important;
  border: none !important;
  margin-top: 30px !important;
  position: relative !important;
  text-align: center !important;
  display: block !important;
  font-weight: 700 !important;
  color: #fff !important;
  background: #1e4242 !important;
  border-radius: 4px !important;
  padding: 16px 10px !important;
  z-index: 1 !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out !important;
}
.login-page-content .login-box .form-box .form-group .login-btn:after {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, #ffdb62, #ffdb62);
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.login-page-content .login-box .form-box .form-group .login-btn:hover:after {
  visibility: visible;
  opacity: 1;
}
.login-page-content .login-box .login-social {
  text-align: center;
}
.login-page-content .login-box .login-social p {
  margin-bottom: 0;
}
.login-page-content .login-box .login-social ul {
  margin-top: 10px;
}
.login-page-content .login-box .login-social ul li {
  display: inline-block;
  margin-right: 2px;
}
.login-page-content .login-box .login-social ul li:last-child {
  margin-right: 0;
}
.login-page-content .login-box .login-social ul li a {
  width: 45px;
  height: 42px;
  line-height: 42px;
  display: inline-block;
  border-radius: 4px;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.login-page-content .login-box .login-social ul li a:hover {
  background-color: #ffdb62;
}
.login-page-content .sign-up {
  color: #000;
  text-align: center;
  margin-top: 10px;
}
.login-page-content .sign-up a {
  color: #1e4242;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.login-page-content .sign-up a:hover {
  color: #ffdb62;
}

/*=======================================================================
7. DASHBOARD STYLES 
=========================================================================*/
/*=============== 7.1 Dashboard Page One ==================*/
/* .dashboard-page-one {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  } 
   .dashboard-content-one {
     min-height: 90vh; 
     display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 30px; 
  }
  @media only screen and (max-width: 767px) {
    .dashboard-content-one {
      padding: 0 15px;
    }
  } */
  
  .dashboard-summery-one {
    /* margin-bottom: 30px; */
    background-color: #ffffff;
    padding: 22px 28px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px 0px rgba(229, 229, 229, 0.75);
  }
  .dashboard-summery-one .item-icon {
    position: relative;
    height: 85px;
    width: 85px;
    line-height: 100px;
    border-radius: 50%;
    text-align: center;
  }
  .dashboard-summery-one .item-icon i:before {
    margin-left: 0;
    font-size: 44px;
  }
  .dashboard-summery-one .item-content {
    text-align: right;
  }
  .dashboard-summery-one .item-content .item-title {
    color: #a8a8a8;
    margin-bottom: 2px;
  }
  .dashboard-summery-one .item-content .item-number {
    font-size: 20px;
    font-weight: 500;
    color: #111111;
  }
  @media only screen and (max-width: 991px) {
    .dashboard-summery-one .item-content .item-number {
      font-size: 20px;
    }
  }
  .dashboard-card-one .card-body .earning-report {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 28px;
  }
  @media only screen and (max-width: 991px) {
    .dashboard-card-one .card-body .earning-report {
      margin-bottom: 20px;
      display: block;
    }
  }
  .dashboard-card-one .card-body .earning-report .item-content .single-item {
    display: inline-block;
    margin-right: 40px;
    padding-left: 34px;
    position: relative;
  }
  @media only screen and (max-width: 991px) {
    .dashboard-card-one .card-body .earning-report .item-content .single-item {
      margin-bottom: 20px;
    }
  }
  .dashboard-card-one .card-body .earning-report .item-content .single-item:after {
    content: "";
    height: 18px;
    width: 18px;
    border: 3px solid #ffffff;
    -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .dashboard-card-one .card-body .earning-report .item-content .single-item:last-child {
    margin-right: 0;
  }
  .dashboard-card-one .card-body .earning-report .item-content .single-item h4 {
    color: #9f9f9f;
    margin-bottom: 4px;
    font-size: 16px;
  }
  .dashboard-card-one .card-body .earning-report .item-content .single-item span {
    padding-left: 13px;
    font-size: 22px;
    font-weight: 500;
    color: #222222;
    position: relative;
    display: inline-block;
  }
  @media only screen and (max-width: 991px) {
    .dashboard-card-one .card-body .earning-report .item-content .single-item span {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .dashboard-card-one .card-body .earning-report .item-content .single-item span {
      font-size: 18px;
    }
  }
  .dashboard-card-one .card-body .earning-report .item-content .single-item span:after {
    content: "$";
    font-size: 16px;
    color: #646464;
    font-weight: 500;
    position: absolute;
    top: 3px;
    left: 0;
  }
  .dashboard-card-one .card-body .earning-report .dropdown {
    text-align: right;
  }
  @media only screen and (max-width: 991px) {
    .dashboard-card-one .card-body .earning-report .dropdown {
      display: none;
    }
  }
  .dashboard-card-one .card-body .earning-report .dropdown .date-dropdown-toggle {
    color: #a7a7a7;
    position: relative;
    padding-right: 24px;
  }
  .dashboard-card-one .card-body .earning-report .dropdown .date-dropdown-toggle:after {
    content: "\f108";
    font-family: Flaticon;
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    right: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5;
    color: #222222;
  }
  .dashboard-card-one .card-body .earning-report .dropdown .dropdown-menu {
    top: 20px !important;
    min-width: 15rem;
    animation: none;
  }
  .dashboard-card-one .card-body .earning-report .dropdown .dropdown-menu .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
  }
  .dashboard-card-one .card-body .earning-chart-wrap {
    position: relative;
  }
  .dashboard-card-two .card-body .expense-report {
    display: flex;
    margin-bottom: 38px;
  }
  .dashboard-card-two .card-body .expense-report .monthly-expense {
    position: relative;
    margin-right: 40px;
  }
  @media only screen and (max-width: 1699px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
      margin-right: 13px;
    }
  }
  @media only screen and (max-width: 1499px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
      margin-right: 30px;
    }
  }
  @media only screen and (max-width: 1299px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
      margin-right: 13px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
      margin-right: 40px;
    }
  }
  @media only screen and (max-width: 479px) {
    .dashboard-card-two .card-body .expense-report .monthly-expense {
      margin-right: 15px;
    }
  }
  .dashboard-card-two .card-body .expense-report .monthly-expense:last-child {
    margin-right: 0;
  }
  .dashboard-card-two .card-body .expense-report .monthly-expense:after {
    content: "";
    height: 4px;
    width: 100%;
    border-radius: 6px;
    position: absolute;
    bottom: -7px;
    left: 0;
    right: 0;
  }
  .dashboard-card-two .card-body .expense-report .monthly-expense .expense-date {
    font-size: 15px;
    color: #9f9f9f;
  }
  .dashboard-card-two .card-body .expense-report .monthly-expense .expense-amount {
    color: #222222;
    font-weight: 600;
    font-size: 16px;
  }
  .dashboard-card-two .card-body .expense-report .monthly-expense .expense-amount span {
    font-size: 16px;
    color: #646464;
  }
  .dashboard-card-three .card-body .doughnut-chart-wrap {
    padding: 5px 0 50px;
    display: flex;
    justify-content: center;
  }
  .dashboard-card-three .card-body .student-report {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .dashboard-card-three .card-body .student-report:before {
    content: "";
    height: 58px;
    width: 1px;
    background-color: #d7d7d7;
    position: absolute;
    top: -15px;
    right: 50%;
    transform: translateX(-50%);
  }
  .dashboard-card-three .card-body .student-report .student-count {
    position: relative;
  }
  .dashboard-card-three .card-body .student-report .student-count .item-title {
    font-size: 14px;
    color: #a5a5a5;
    margin-bottom: 2px;
  }
  .dashboard-card-three .card-body .student-report .student-count .item-number {
    font-size: 16px;
    color: #111111;
    font-weight: 500;
  }
  .dashboard-card-three .card-body .student-report .student-count:after {
    content: "";
    height: 6px;
    width: 49px;
    border-radius: 6px;
    position: absolute;
    top: -18px;
    left: 0;
  }
  .dashboard-card-three .card-body .student-report .student-count:last-child:before {
    display: none;
  }
  .dashboard-card-four .card-body .calender-wrap .fc-calender .fc-view-container .fc-basicWeek-view table .fc-head .fc-head-container .fc-day-header {
    font-size: 14px;
  }
  .dashboard-card-five .card-body .traffic-title {
    color: #a5a5a5;
    margin-bottom: 10px;
  }
  .dashboard-card-five .card-body .traffic-number {
    font-size: 30px;
    font-weight: 700;
    color: #222222;
    line-height: 1;
    margin-bottom: 25px;
  }
  .dashboard-card-five .card-body .traffic-bar {
    display: flex;
    margin-bottom: 18px;
  }
  .dashboard-card-five .card-body .traffic-bar .direct {
    flex: 0 0 50%;
    max-width: 50%;
    min-height: 10px;
    background-color: #1de9b6;
    border-radius: 40px 0 0 40px;
  }
  .dashboard-card-five .card-body .traffic-bar .search {
    flex: 0 0 27%;
    max-width: 27%;
    min-height: 10px;
    background-color: #304ffe;
  }
  .dashboard-card-five .card-body .traffic-bar .referrals {
    flex: 0 0 16%;
    max-width: 16%;
    min-height: 10px;
    background-color: #ffea00;
  }
  .dashboard-card-five .card-body .traffic-bar .social {
    flex: 0 0 7%;
    max-width: 7%;
    min-height: 10px;
    background-color: #ed1c24;
    border-radius: 0 40px 40px 0;
  }
  .dashboard-card-five .card-body .traffic-table .table tbody tr {
    border-bottom: 1px dashed #e1e1e1;
  }
  .dashboard-card-five .card-body .traffic-table .table tbody tr:last-child {
    border-bottom: none;
  }
  .dashboard-card-five .card-body .traffic-table .table tbody tr td {
    color: #111111;
    font-weight: 500;
    border-top: none;
    padding: 20px 15px;
  }
  .dashboard-card-five .card-body .traffic-table .table tbody tr .t-title {
    position: relative;
    padding-left: 34px;
  }
  .dashboard-card-five .card-body .traffic-table .table tbody tr .t-title:after {
    content: "";
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
  }
  .dashboard-card-six ::-webkit-scrollbar {
    width: 10px;
    background: #f0f1f2;
    border-radius: 30px;
  }
  .dashboard-card-six ::-webkit-scrollbar-track {
    border-radius: 30px;
    background: #f0f1f2;
  }
  .dashboard-card-six ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: #3751fe;
  }
  .dashboard-card-six .card-body .notice-box-wrap {
    max-height: 358px;
    overflow-y: scroll;
    padding-right: 20px;
  }
  .dashboard-card-six .card-body .notice-box-wrap .notice-list {
    border-bottom: 1px solid #ededed;
    padding-bottom: 14px;
    margin-bottom: 18px;
  }
  .dashboard-card-six .card-body .notice-box-wrap .notice-list .post-date {
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    padding: 5px 14px;
    border-radius: 20px;
    margin-bottom: 14px;
  }
  .dashboard-card-six .card-body .notice-box-wrap .notice-list .notice-title {
    font-weight: 500;
    margin-bottom: 8px;
  }
  .dashboard-card-six .card-body .notice-box-wrap .notice-list .notice-title a {
    color: #111111;
  }
  .dashboard-card-six .card-body .notice-box-wrap .notice-list .entry-meta {
    font-size: 15px;
    color: #646464;
  }
  .dashboard-card-six .card-body .notice-box-wrap .notice-list .entry-meta span {
    color: #a4a4a4;
  }
  .dashboard-card-seven .social-media {
    border-radius: 4px;
    padding: 30px 20px;
    text-align: center;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .dashboard-card-seven .social-media .media {
    align-items: center;
    justify-content: center;
  }
  .dashboard-card-seven .social-media .media .social-icon {
    font-size: 24px;
    color: #ffffff;
  }
  .dashboard-card-seven .social-media .media .media-body {
    flex: inherit;
  }
  .dashboard-card-seven .social-media .media .media-body .item-title {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 15px;
  }
  @media only screen and (max-width: 1199px) {
    .dashboard-card-seven .social-media .media .media-body {
      margin-top: 10px;
    }
  }
  .dashboard-card-seven .social-media .social-like {
    font-size: 26px;
    color: #ffffff;
  }
  /*=============== 7.2 Dashboard Page Two ==================*/
  .dashboard-card-ten .card-body .student-info .media {
    margin-bottom: 6px;
  }
  @media only screen and (max-width: 1499px) {
    .dashboard-card-ten .card-body .student-info .media {
      margin-bottom: 30px;
    }
  }
  .dashboard-card-ten .card-body .student-info .media .item-img {
    background-color: #4dd0e1;
    height: 115px;
    width: 115px;
    line-height: 129px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
  }
  .dashboard-card-ten .card-body .student-info .media .item-img img {
    margin-right: 5px;
  }
  .dashboard-card-ten .card-body .student-info .media .media-body .item-title {
    color: #111111;
    font-weight: 500;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 1499px) {
    .dashboard-card-ten .card-body .student-info .info-table .table {
      width: 70%;
    }
  }
  @media only screen and (max-width: 991px) {
    .dashboard-card-ten .card-body .student-info .info-table .table {
      width: 100%;
    }
  }
  .dashboard-card-ten .card-body .student-info .info-table .table tbody tr td {
    border: none;
    padding: 8px;
  }
  .dashboard-card-eleven .card-body .table-box-wrap .search-form-box {
    margin-bottom: 18px;
  }
  /*=============== 7.3 Dashboard Page Three ==================*/
  .dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box {
    display: flex;
    background-color: #fafafa;
    padding: 40px 30px 28px;
  }
  @media only screen and (max-width: 575px) {
    .dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box {
      display: block;
    }
  }
  .dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box .item-img {
    height: 8rem;
    width: 8rem;
    background-color: #4dd0e1;
    border-radius: 50%;
    text-align: center;
    overflow: hidden;
    margin-right: 3rem;
  }
  @media only screen and (max-width: 575px) {
    .dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box .item-img {
      margin: 0 auto 30px;
    }
  }
  .dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box .item-content {
    flex: 1;
  }
  .dashboard-card-twelve .card-body .kids-details-wrap .kids-details-box .item-content .table tbody tr td {
    border: none;
    padding: 0 10px;
    color: #333333;
  }
  /*=============== 7.4 Dashboard Page Four ==================*/
  .dashboard-summery-two {
    margin-bottom: 30px;
    background-color: #ffffff;
    padding: 30px 20px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px 0px rgba(229, 229, 229, 0.75);
    text-align: center;
  }
  .dashboard-summery-two .item-icon {
    position: relative;
    height: 85px;
    width: 85px;
    line-height: 85px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto 6px;
  }
  .dashboard-summery-two .item-icon i:before {
    margin-left: 0;
    font-size: 44px;
  }
  .dashboard-summery-two .item-content .item-title {
    color: #a8a8a8;
    margin-bottom: 2px;
  }
  @media only screen and (max-width: 1199px) {
    .dashboard-summery-two .item-content .item-title {
      font-size: 14px;
    }
  }
  .dashboard-summery-two .item-content .item-number {
    font-size: 28px;
    font-weight: 500;
    color: #111111;
  }
  @media only screen and (max-width: 1499px) {
    .dashboard-summery-two .item-content .item-number {
      font-size: 26px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .dashboard-summery-two .item-content .item-number {
      font-size: 20px;
    }
  }
  .menulogoonlog{
    color: #000;
    font-weight: 600;
    font-size: 30px;
}
.menulogo{
  color: #000;
  font-weight: 500;
  padding-left: 20px !important;
  font-size: 12px;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
/* table {
  margin-top: 20px;
} */
.table-responsive{
  border-radius: 10px;
}
.sticky-column {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  background-color: white; /* Ensure the background matches your page */
  padding: 15px;
  border: 1px solid #ddd; /* Optional: add a border for better visibility */
  z-index: 1000; /* Optional: ensure it appears above other content */
}
